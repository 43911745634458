<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">潜在供应商审核</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        帮助你审查潜在供应商是否具备承担采购项目所需的专业资质和经验，以及相应的技术水平和生产能力，从局外人解决确保采购过程的合规性和公正性。
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        减少贵公司长期培育大量专业的SQE人员的成本
        <br />
        使用VDA6.3原理及检查题库
        <br />
        利用软件构建供应商资料库
        <br />
        无线化的低成本供应商管理系统
        <br />
        便于供应商管理的知识经验的传承
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        提供供应商审核平台
        <br />
        提供供应商审核的关键点知识培训，可线上可线下
        <br />
        供应商审核外包，审核情况在平台上实时显示
      </p>
      <br />
      <p class="PTitle LeftBor">成果展示：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/74.png"
        class="SonImg"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>